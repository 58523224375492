import { useEffect, useState } from "react";
import Accordion from "../common/Accordion";
import LazyLoadImage from "../common/LazyLoadImage";

const ConcussionAwarenessNow = ({
    setElementScrollTop,
    clickedBtn,
    setClickedBtn,
    visibility,
    setVisibility,
}) => {
    const [colorPalette, setColorPalette] = useState("");
    const [canWeb, setCanWeb] = useState("");
    const [comp1, setComp1] = useState("");
    const [canIcons, setCanIcons] = useState("");

    useEffect(() => {
        setTimeout(() => {
            setColorPalette("/img/can colorpalette_1.mp4");
            setCanWeb("/img/can web 1.mp4");
            setComp1("/img/Comp1_3.mp4");
            setCanIcons("/img/CAN-icons.mp4");
        }, 2000);
    }, []);

    return (
        <Accordion
            title={"Concussion Awareness Now"}
            id={"concussion"}
            clickedBtn={clickedBtn}
            setClickedBtn={(clickedBtn) => setClickedBtn(clickedBtn)}
            visibility={visibility}
            setVisibility={(visibility) => setVisibility(visibility)}
            setElementScrollTop={(offset) => setElementScrollTop(offset)}
        >
            <p>
                A coalition of nearly 20 member organizations was founded to
                bring awareness to the seriousness of concussions. Spearheaded
                by the Brain Injury Association of America [BIAA] and Abbott,
                the coalitions’ goal is to educate as many people as possible
                about concussions and to offer resources to make informed
                decisions in case of a hit in the head or a simple slip or fall.
                We helped the coalition to create the brand from the ground up,
                creating the entire brand expression from brand strategy, brand
                purpose, naming, tone of voice, website, social strategy all the
                way to the launch campaign.
                <br />
                <font color="#D3D3D3">
                    Developed by Hugo Collective w/ Christian Dierig, Donna
                    Hadfield, Baron Santiago & Paco Aguayo. 
                    3D Character Illustrattion by Nathan Love.
                </font>
            </p>

            <div className="flex flex-col">
                <div className="py-4 w-full max-md:py-3 max-sm:py-2">
                    <LazyLoadImage
                        alt="..."
                        src="/img/can_logo.gif"
                        className="w-full h-full object-cover"
                    />
                </div>
                <div className="py-4 w-full max-md:py-3 max-sm:py-2">
                    <LazyLoadImage
                        alt="..."
                        src="/img/can_casestudy_12.jpg"
                        className="w-full h-full object-cover"
                    />
                </div>
                <div className="py-4 w-full  max-md:py-3 max-sm:py-2">
                    <video
                        src={colorPalette}
                        className="w-full h-full object-cover"
                        autoPlay
                        muted
                        loop
                        playsInline
                    ></video>
                </div>
                <div className="py-4 w-full max-md:py-3 max-sm:py-2">
                    <LazyLoadImage
                        alt="..."
                        src="/img/can_casestudy_22.jpg"
                        className="w-[40%] h-full float-left object-cover max-md:w-full"
                    />
                </div>
                <div className="py-4 w-full max-md:py-3 max-sm:py-2">
                    <LazyLoadImage
                        alt="..."
                        src="/img/can_casestudy_04.jpg"
                        className="w-full h-full object-cover"
                    />
                </div>
                <div className="py-4 w-full max-md:py-3 max-sm:py-2">
                    <LazyLoadImage
                        alt="..."
                        src="/img/can_casestudy_07.jpg"
                        className="w-full h-full object-cover"
                    />
                </div>
                <div className="py-4 w-full max-md:py-3 max-sm:py-2">
                    <LazyLoadImage
                        alt="..."
                        src="/img/can_casestudy_21.jpg"
                        className="w-[40%] h-full float-right object-cover max-md:w-full"
                    />
                </div>
                <div className="py-4 w-full max-md:py-3 max-sm:py-2">
                    <video
                        src={canWeb}
                        className="w-full h-full object-cover"
                        autoPlay
                        muted
                        loop
                        playsInline
                    ></video>
                </div>
                <div className="py-4 w-full max-md:py-3 max-sm:py-2">
                    <LazyLoadImage
                        alt="..."
                        src="/img/can_casestudy_10.jpg"
                        className="w-[50%] h-full float-left object-cover max-md:w-full"
                    />
                </div>
                <div className="py-4 w-full max-md:py-3 max-sm:py-2">
                    <LazyLoadImage
                        alt="..."
                        src="/img/can_casestudy_08.jpg"
                        className="w-full h-full object-cover"
                    />
                </div>
                <div className="py-4 w-full max-md:py-3 max-sm:py-2">
                    <LazyLoadImage
                        alt="..."
                        src="/img/can_casestudy_16.jpg"
                        className="w-full h-full object-cover"
                    />
                </div>
                <div className="py-4 w-full max-md:py-3 max-sm:py-2">
                    <LazyLoadImage
                        alt="..."
                        src="/img/can_flyer.gif"
                        className="w-[50%] h-full float-right object-cover max-md:w-full"
                    />
                </div>
                <div className="py-4 w-full max-md:py-3 max-sm:py-2">
                    <LazyLoadImage
                        alt="..."
                        src="/img/can_casestudy_23.jpg"
                        className="w-full h-full object-cover"
                    />
                </div>
                <div className="py-4 w-full max-md:py-3 max-sm:py-2">
                    <LazyLoadImage
                        alt="..."
                        src="/img/can_casestudy_05.jpg"
                        className="w-full h-full object-cover"
                    />
                </div>
                <div className="py-4 w-full max-md:py-3 max-sm:py-2">
                    <LazyLoadImage
                        alt="..."
                        src="/img/can_casestudy_20.jpg"
                        className="w-[40%] h-full float-left object-cover max-md:w-full"
                    />
                </div>
                <div className="py-4 w-full max-md:py-3 max-sm:py-2">
                    <video
                        src={comp1}
                        className="w-full h-full object-cover"
                        autoPlay
                        muted
                        loop
                        playsInline
                    ></video>
                </div>
                <div className="py-4 w-full max-md:py-3 max-sm:py-2">
                    <LazyLoadImage
                        alt="..."
                        src="/img/can_casestudy_17.jpg"
                        className="w-full h-full object-cover"
                    />
                </div>
                <div className="py-4 w-full max-md:py-3 max-sm:py-2">
                    <LazyLoadImage
                        alt="..."
                        src="/img/can_casestudy_14.jpg"
                        className="w-full h-full object-cover"
                    />
                </div>
                <div className="py-4 w-full max-md:py-3 max-sm:py-2">
                    <video
                        src={canIcons}
                        className="w-[40%] h-full float-right object-cover max-md:w-full"
                        autoPlay
                        muted
                        loop
                        playsInline
                    ></video>
                </div>
                <div className="py-4 w-full max-md:py-3 max-sm:py-2">
                    <LazyLoadImage
                        alt="..."
                        src="/img/can_casestudy_11.jpg"
                        className="w-full h-full object-cover"
                    />
                </div>
                <div className="py-4 w-full max-md:py-3 max-sm:py-2">
                    <LazyLoadImage
                        alt="..."
                        src="/img/can_casestudy_09.jpg"
                        className="w-full h-full object-cover"
                    />
                </div>
                <div className="py-4 w-full max-md:py-3 max-sm:py-2">
                    <LazyLoadImage
                        alt="..."
                        src="/img/can_casestudy_18.jpg"
                        className="w-full h-full object-cover"
                    />
                </div>
            </div>
        </Accordion>
    );
};
export default ConcussionAwarenessNow;
