import { useEffect, useState } from "react";
import Accordion from "../common/Accordion";
import LazyLoadImage from "../common/LazyLoadImage";

const Lincoln = ({
  setElementScrollTop,
  clickedBtn,
  setClickedBtn,
  visibility,
  setVisibility,
}) => {
   const [lincolnLogo, setLincolnLogo] = useState("");
   const [lincolnColors, setLincolnColors] = useState("");
   const [lincolnType01, setLincolnType01] = useState("");
   const [lincolnType02, setLincolnType02] = useState("");
    const [lincolnType03, setLincolnType03] = useState("");
   const [lincolnSocial01, setLincolnSocial01] = useState("");
   const [lincolnIcons, setLincolnIcons] = useState("");
   const [lincolnWebscroll, setLincolnWebscroll] = useState("");
    const [lincolnWebscroll2, setLincolnWebscroll2] = useState("");
    const [lincolnCharts, setLincolnCharts] = useState("");
   
    useEffect(() => {
        setTimeout(() => {
            setLincolnLogo("/img/lincoln_logo.mp4");
            setLincolnColors("/img/lincoln_colors.mp4");
            setLincolnType01("/img/lincoln_type01.mp4");
            setLincolnType02("/img/lincoln_type02.mp4");
            setLincolnSocial01("/img/lincoln_social01.mp4");
            setLincolnIcons("/img/lincoln_icons.mp4");
            setLincolnWebscroll("/img/lincoln_webscroll.mp4");
            setLincolnWebscroll2("/img/lincoln_webscroll2.mp4");
            setLincolnType03("/img/lincoln_type03.mp4");
            setLincolnCharts("/img/lincoln_charts.mp4");
        }, 2000);
    }, []);
    
  return (
    <Accordion
      title={"Lincoln Property Company"}
      id="lincoln"
      clickedBtn={clickedBtn}
      setClickedBtn={(clickedBtn) => setClickedBtn(clickedBtn)}
      visibility={visibility}
      setVisibility={(visibility) => setVisibility(visibility)}
      setElementScrollTop={(offset) => setElementScrollTop(offset)}
    >
      <p>
        Following a period of transformative growth, new executive leadership and major investment, Lincoln Property Company needed to modernize and unify their branding to reflect a new era of expanded capabilities and global reach. Over the course of 7 months, we developed an entirely new brand, extending to all social media platforms, and including new visual and verbal branding elements like a new logo, color, photography, website, and merchandise. We produced a teaser video that went on to get 100,000 impressions, while a paid LinkedIn campaign featuring Lincoln’s Co-CEOs enhanced visibility and credibility. Perhaps most significantly, we helped Lincoln achieve a significant milestone, launching one unified website to replace 17 separate Lincoln sites. To complement the bold sophistication of Lincoln’s new brand, we selected a palette impossible to ignore and hard to resist. Yellow evokes warmth and friendliness. Black adds strength and confidence. Together, they’re the colors of a brand new day.We created a custom motion toolkit to truly bring the visual identity to life. Dynamic movement across all digital platforms ensured the Lincoln brand could move fast enough to keep up with the company. Containing interwoven bespoke “c” and “o” letteforms, Lincoln’s new logo puts the concept of connection front and center, forming a testament to strategic partnerships and collaboration. Through bold lighting and warm tones, the photography style captures the entrepreneurial spirit of everyday moments and interactions, reflecting the new brand's warmth and approachability.
         <br />
                <font color="#D3D3D3">
                    Developed by Prosek Partners w/ Scott Buschkuhl, Tim Chan & Sara Haney.
                    Motion by Boris Poletaev.
                </font>
            </p>

      <div className="flex flex-col">
        <div className="py-4 w-full max-md:py-3 max-sm:py-2">
                    <video
                        src={lincolnLogo}
                        className="w-full h-full object-cover"
                        autoPlay
                        muted
                        loop
                        playsInline
                     ></video>
                </div>
        
        <div className="py-4 w-full max-md:py-3 max-sm:py-2">
          <LazyLoadImage
            alt="..."
            id="222"
            src="/img/lincoln_casestudy_01.jpg"
            className="w-[40%] h-full float-left object-cover max-md:w-full"
          />
        </div>
         <div className="flex flex-col">
        <div className="py-4 w-full max-md:py-3 max-sm:py-2">
                    <video
                        src={lincolnColors}
                        className="w-full h-full object-cover"
                        autoPlay
                        muted
                        loop
                        playsInline
                     ></video>
                </div>
        </div>
        <div className="py-4 w-full max-md:py-3 max-sm:py-2">
          <LazyLoadImage
            alt="..."
            src="/img/lincoln_casestudy_02.jpg"
            className="w-[40%] h-full float-right object-cover max-md:w-full"
          />
        </div>
         <div className="flex flex-col">
        <div className="py-4 w-full max-md:py-3 max-sm:py-2">
                    <video
                        src={lincolnType01}
                        className="w-full h-full object-cover"
                        autoPlay
                        muted
                        loop
                        playsInline
                     ></video>
                </div>
        </div>
        <div className="py-4 w-full max-md:py-3 max-sm:py-2">
          <LazyLoadImage
            alt="..."
            src="/img/lincoln_casestudy_03.jpg"
            className="w-[50%] h-full float-left object-cover max-md:w-full"
          />
            </div>
         <div className="flex flex-col">
        <div className="py-4 w-full max-md:py-3 max-sm:py-2">
                    <video
                        src={lincolnWebscroll}
                        className="w-full h-full object-cover"
                        autoPlay
                        muted
                        loop
                        playsInline
                     ></video>
                </div>
        </div>
        <div className="py-4 w-full max-md:py-3 max-sm:py-2">
          <LazyLoadImage
            alt="..."
            src="/img/lincoln_casestudy11.jpg"
            className="w-full h-full object-cover"
          />
        </div>
          <div className="flex flex-col">
        <div className="py-4 w-full max-md:py-3 max-sm:py-2">
                    <video
                        src={lincolnWebscroll2}
                        className="w-[50%] h-full float-right object-cover max-md:w-full"
                        autoPlay
                        muted
                        loop
                        playsInline
                     ></video>
                </div>
              </div>
        <div className="py-4 w-full max-md:py-3 max-sm:py-2">
          <LazyLoadImage
            alt="..."
            src="/img/lincoln_casestudy06.jpg"
            className="w-full h-full object-cover"
          />
        </div>
        <div className="py-4 w-full max-md:py-3 max-sm:py-2">
          <LazyLoadImage
            alt="..."
            src="/img/lincoln_casestudy07.jpg"
            className="w-[40%] h-full float-left object-cover max-md:w-full"
          />
        </div>
        <div className="flex flex-col">
        <div className="py-4 w-full max-md:py-3 max-sm:py-2">
                    <video
                        src={lincolnSocial01}
                        className="w-full h-full object-cover"
                        autoPlay
                        muted
                        loop
                        playsInline
                     ></video>
                </div>
        </div>
        <div className="flex flex-col">
        <div className="py-4 w-full max-md:py-3 max-sm:py-2">
                    <video
                        src={lincolnCharts}
                        className="w-[50%] h-full float-right object-cover max-md:w-full"
                        autoPlay
                        muted
                        loop
                        playsInline
                     ></video>
                </div>
        </div>
        <div className="py-4 w-full max-md:py-3 max-sm:py-2">
          <LazyLoadImage
            alt="..."
            src="/img/lincoln_casestudy12.jpg"
            className="w-full h-full object-cover"
          />
        </div>
        <div className="py-4 w-full max-md:py-3 max-sm:py-2">
          <LazyLoadImage
            alt="..."
            src="/img/lincoln_grid.gif"
            className="w-[40%] h-full float-left object-cover max-md:w-full"
          />
        </div>
        <div className="py-4 w-full max-md:py-3 max-sm:py-2">
          <LazyLoadImage
            alt="..."
            src="/img/lincolncovers.gif"
            className="w-[40%] h-full float-right object-cover max-md:w-full"
          />
        </div>
        <div className="py-4 w-full max-md:py-3 max-sm:py-2">
          <LazyLoadImage
            alt="..."
            src="/img/lincoln_casestudy15.jpg"
            className="w-full h-full object-cover"
          />
            </div>
        <div className="py-4 w-full max-md:py-3 max-sm:py-2">
          <LazyLoadImage
            alt="..."
            src="/img/lincoln_casestudy17.jpg"
            className="w-full h-full object-cover"
               />
        </div>
        <div className="py-4 w-full max-md:py-3 max-sm:py-2">
          <LazyLoadImage
            alt="..."
            src="/img/lincoln_casestudy22.jpg"
            className="w-[50%] h-full float-left object-cover max-md:w-full"
               />
        </div>
        <div className="py-4 w-full max-md:py-3 max-sm:py-2">
          <LazyLoadImage
            alt="..."
            src="/img/lincoln_casestudy16.jpg"
            className="w-[40%] h-full float-right object-cover max-md:w-full"
          />
        </div>
        <div className="flex flex-col">
        <div className="py-4 w-full max-md:py-3 max-sm:py-2">
                    <video
                        src={lincolnIcons}
                        className="w-full h-full object-cover"
                        autoPlay
                        muted
                        loop
                        playsInline
                    ></video>
                </div>
            </div>
        <div className="py-4 w-full max-md:py-3 max-sm:py-2">
          <LazyLoadImage
            alt="..."
            src="/img/lincoln_casestudy04.jpg"
            className="w-full h-full object-cover"/>
             </div>
        <div className="py-4 w-full max-md:py-3 max-sm:py-2">
          <LazyLoadImage
            alt="..."
            src="/img/lincoln_casestudy21.jpg"
            className="w-[40%] h-full float-left object-cover max-md:w-full"
             />
        </div>
            <div className="py-4 w-full max-md:py-3 max-sm:py-2">
          <LazyLoadImage
            alt="..."
            src="/img/lincoln_casestudy18.jpg"
            className="w-[50%] h-full float-right object-cover max-md:w-full"
               />
            </div>
        <div className="py-4 w-full max-md:py-3 max-sm:py-2">
          <LazyLoadImage
            alt="..."
            src="/img/lincoln_casestudy05.jpg"
            className="w-full h-full object-cover"
           />
        </div>
      </div>
    </Accordion>
  );
};


export default Lincoln;
