import Accordion from "../common/Accordion";
import LazyLoadImage from "../common/LazyLoadImage";

const SeedsofPeace = ({
  setElementScrollTop,
  clickedBtn,
  setClickedBtn,
  visibility,
  setVisibility,
}) => {
  return (
    <Accordion
      title={"Seeds of Peace"}
      id="seeds"
      clickedBtn={clickedBtn}
      setClickedBtn={(clickedBtn) => setClickedBtn(clickedBtn)}
      visibility={visibility}
      setVisibility={(visibility) => setVisibility(visibility)}
      setElementScrollTop={(offset) => setElementScrollTop(offset)}
    >
      <p>
        Established in 1993, non-profit organization Seeds of Peace inspires and
        cultivates new generations of leaders to come together and transform
        conflict. This happens each summer as hundreds of teenagers and
        educators engage with one another across lines of conflict.
        Transformation was an important characteristic behind the visual
        identity, allowing for various but consistent messaging across various
        types of materials.
        <br />
        <font color="#D3D3D3">
          Developed by Prophet w/ Craig Stout, Donna Hadfield & Sarita Walsh
        </font>
      </p>

      <div className="flex flex-col">
        <div className="py-4 w-full max-md:py-3 max-sm:py-2">
          <LazyLoadImage
            alt="..."
            src="/img/sop_casestudy_web-01.jpg"
            className="w-full h-full object-cover"
          />
        </div>
        <div className="py-4 w-full max-md:py-3 max-sm:py-2">
          <LazyLoadImage
            alt="..."
            src="/img/seeds.gif"
            className="w-full h-full object-cover"
          />
        </div>
        <div className="py-4 w-full max-md:py-3 max-sm:py-2">
          <LazyLoadImage
            alt="..."
            src="/img/sop_casestudy_web-12.jpg"
            className="w-[50%] h-full float-right object-cover max-md:w-full"
          />
        </div>
        <div className="py-4 w-full max-md:py-3 max-sm:py-2">
          <LazyLoadImage
            alt="..."
            src="/img/sop_casestudy_web-06.jpg"
            className="w-full h-full object-cover"
          />
        </div>
        <div className="py-4 w-full max-md:py-3 max-sm:py-2">
          <LazyLoadImage
            alt="..."
            src="/img/sop_casestudy_web-05.jpg"
            className="w-full h-full object-cover"
          />
        </div>
        <div className="py-4 w-full max-md:py-3 max-sm:py-2">
          <LazyLoadImage
            alt="..."
            src="/img/sop_casestudy_web-13.jpg"
            className="w-[50%] h-full float-left object-cover max-md:w-full"
          />
        </div>
        <div className="py-4 w-full max-md:py-3 max-sm:py-2">
          <LazyLoadImage
            alt="..."
            src="/img/sop_casestudy_web-03.jpg"
            className="w-full h-full object-cover"
          />
        </div>
        <div className="py-4 w-full max-md:py-3 max-sm:py-2">
          <LazyLoadImage
            alt="..."
            src="/img/sop_casestudy_web-07.jpg"
            className="w-full h-full object-cover"
          />
        </div>
        <div className="py-4 w-full max-md:py-3 max-sm:py-2">
          <LazyLoadImage
            alt="..."
            src="/img/sop_casestudy_web-14.jpg"
            className="w-[50%] h-full float-right object-cover max-md:w-full"
          />
        </div>
        <div className="py-4 w-full max-md:py-3 max-sm:py-2">
          <LazyLoadImage
            alt="..."
            src="/img/sop_casestudy_web-08.jpg"
            className="w-full h-full object-cover"
          />
        </div>
        <div className="py-4 w-full max-md:py-3 max-sm:py-2">
          <LazyLoadImage
            alt="..."
            src="/img/seedsofpeace_casestudy_web-10.jpg"
            className="w-full h-full object-cover"
          />
        </div>
        <div className="py-4 w-full max-md:py-3 max-sm:py-2">
          <LazyLoadImage
            alt="..."
            src="/img/sop_casestudy_web-15.jpg"
            className="w-[50%] h-full float-left object-cover max-md:w-full"
          />
        </div>
        <div className="py-4 w-full max-md:py-3 max-sm:py-2">
          <LazyLoadImage
            alt="..."
            src="/img/sop_casestudy_web-09.jpg"
            className="w-full h-full object-cover"
          />
        </div>
        <div className="py-4 w-full max-md:py-3 max-sm:py-2">
          <LazyLoadImage
            alt="..."
            src="/img/sop_casestudy_web-04.jpg"
            className="w-full h-full object-cover"
          />
        </div>
        <div className="py-4 w-full max-md:py-3 max-sm:py-2">
          <LazyLoadImage
            alt="..."
            src="/img/sop_casestudy_web-11.jpg"
            className="w-full h-full object-cover"
          />
        </div>
        <div className="py-4 w-full max-md:py-3 max-sm:py-2">
          <LazyLoadImage
            alt="..."
            src="/img/sop_casestudy_web-16.jpg"
            className="w-full h-full object-cover"
          />
        </div>
      </div>
    </Accordion>
  );
};

export default SeedsofPeace;
